import Back from '@/components/back/back';
import { Image, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PlayBtn from '@/assets/img/play-p.png';
import Check from '@/assets/img/Check.png';
import VideoPlayModal from '@/components/modal/video-play-modal';
import PortFolioDrawer from '@/components/portfolio-drawer';
import api from '@/api/candidates';
import { defaultInfo } from '@/typings/allType';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import UserImg1 from '@/assets/img/user1.png';
import UserImg2 from '@/assets/img/user2.png';
import UserImg3 from '@/assets/img/user3.png';
import UserImg4 from '@/assets/img/user4.png';
import UserImg5 from '@/assets/img/user5.png';
import UserImg6 from '@/assets/img/user6.png';
function CandidatesDetail() {
    const [loading, setLoading] = useState(false);
    const [videoList, setVideoList] = useState<any>([
        { tit: 'Self Introduction', url: '', coverPic: '', desc: '' },
        { tit: 'Key Skills and Experience ', url: '', coverPic: '', desc: '' },
        { tit: 'Key Personal Strength', url: '', coverPic: '', desc: '' },
    ]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [accountCertificatePOJOList, setAccountCertificatePOJOList] = useState<any>([]);
    const [relevantExperience, setRelevantExperience] = useState<any>([]);
    const [relevantExperienceTime, setRelevantExperienceTime] = useState<string>('');
    const [accountProjectPOJOList, setAccountProjectPOJOList] = useState<any>([]);
    const [showMoreProject, setShowMoreProject] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [projectInfo, setProjectInfo] = useState<any>({});
    const [skillList, setSkillList] = useState<any>([]);
    const [softSkillList, setSoftSkillList] = useState<any>([]);
    const [nowUserInfo, setNowUserInfo] = useState({
        accountId: '',
        interviewTimeShow: '',
        myScoreFlag: true,
        applicationsScoreTotal: 0,
        applicationsId: '',
        appliedTime: 0,
        accountPOJO: {
            accountInformationPOJO: {} as any,
        },
        accountInformationPOJO: {} as defaultInfo,
        jobsPOJO: {},
        hardSkill: [],
        softSkill: [],
        screeningQuestionsListPOJO: [] as defaultInfo[],
        businessAccountFavoriteFlag: false,
    });
    const [localUserInfo, setLocalUserInfo] = useState<any>({});
    const getUserInfo = val => {
        api.getAccountInfo({ accountId: val.accountId, applicationsId: val.applicationsId }, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                const hardSkill: any = [];
                const softSkill: any = [];
                if (data.accountSkillPOJOList && data.accountSkillPOJOList.length) {
                    data.accountSkillPOJOList.forEach(item => {
                        if (item.skillType === 'softSkills') {
                            softSkill.push(item);
                        } else {
                            hardSkill.push(item);
                        }
                    });
                }
                if (data.accountSkillPOJOList && data.accountSkillPOJOList !== null) {
                    const skillList: any = [];
                    const softSkillList: any = [];
                    data.accountSkillPOJOList.forEach(item => {
                        if (item.skillType === 'softSkills') {
                            softSkillList.push(item);
                        } else {
                            skillList.push(item);
                        }
                        setSkillList([...skillList]);
                        setSoftSkillList([...softSkillList]);
                    });
                }
                data.accountInformationPOJO.birth = data.accountInformationPOJO.birth
                    ? dayjs(data.accountInformationPOJO.birth).format('MMM YYYY')
                    : '';
                data.hardSkill = hardSkill;
                data.softSkill = softSkill;
                setNowUserInfo({ ...data });
                if (data.accountExperiencePOJO) {
                    const year = data.accountExperiencePOJO.yearsOfExperience;
                    const month = data.accountExperiencePOJO.monthOfExperience;
                    const time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                        month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                    }`;
                    setRelevantExperienceTime(time);
                    setRelevantExperience([...data.accountExperiencePOJO.accountExperienceInfoPOJOList]);
                }
                if (data.accountCertificatePOJOList && data.accountCertificatePOJOList !== null) {
                    const val = cloneDeep(data.accountCertificatePOJOList);
                    setAccountCertificatePOJOList(val);
                }
                if (data.accountProjectPOJOList && data.accountProjectPOJOList !== null) {
                    setAccountProjectPOJOList([...data.accountProjectPOJOList]);
                }
                const videos = [
                    {
                        tit: 'Self Introduction',
                        url: data.accountInformationPOJO.selfIntroductionUrl,
                        coverPic: data.accountInformationPOJO.selfIntroductionUrlImage,
                        desc: data.accountInformationPOJO.selfIntroductionDesc || '',
                    },
                    {
                        tit: 'Key Skills and Experience ',
                        url: data.accountInformationPOJO.keySkillsExperiencesUrl,
                        coverPic: data.accountInformationPOJO.keySkillsExperiencesUrlImage,
                        desc: data.accountInformationPOJO.keySkillsExperiencesDesc || '',
                    },
                    {
                        tit: 'Key Personal Strength',
                        url: data.accountInformationPOJO.keyPersonalStrengthUrl,
                        coverPic: data.accountInformationPOJO.keyPersonalStrengthUrlImage,
                        desc: data.accountInformationPOJO.keyPersonalStrengthDesc || '',
                    },
                ];
                setVideoList([...videos]);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const getLocalCandidateInfo = () => {
        const info = JSON.parse(window.sessionStorage.getItem('candidateInfo') || "{accountId:''}");
        if (info.accountId) {
            setLocalUserInfo(info);
            setLoading(true);
            getUserInfo(info);
        }
    };
    useEffect(() => {
        getLocalCandidateInfo();
    }, []);
    return (
        <div className="c-pageContent">
            <div>
                <Back />
            </div>
            {loading ? (
                <div className="r-center" style={{ padding: '80px 0' }}>
                    <Spin />
                </div>
            ) : (
                <div>
                    <div className="userInfo-box">
                        <div className="r-top">
                            <Image
                                width={72}
                                height={72}
                                preview={false}
                                style={{ borderRadius: 8, minWidth: 72 }}
                                src={
                                    nowUserInfo.accountInformationPOJO.profilePhoto
                                        ? nowUserInfo.accountInformationPOJO.profilePhoto
                                        : ''
                                }
                                fallback={require('@/assets/img/default-img.png')}
                            />
                            <div style={{ marginLeft: 16 }}>
                                <div className="userName-box">
                                    {localUserInfo.namelabel || '-'}
                                    {
                                        nowUserInfo.accountInformationPOJO.preferredPronounsFlag
                                            ? `(${
                                                  nowUserInfo.accountInformationPOJO.preferredPronounsCustomFlag
                                                      ? nowUserInfo.accountInformationPOJO.preferredPronounsCustom
                                                      : nowUserInfo.accountInformationPOJO.preferredPronounsList
                                              })`
                                            : ''
                                        // nowUserInfo.accountInformationPOJO.preferredPronounsCustomFlag
                                        //     ? `(${nowUserInfo.accountInformationPOJO.preferredPronounsCustom})`
                                        //     : `(${nowUserInfo.accountInformationPOJO.preferredPronounsList})`
                                        // : ''
                                    }
                                </div>
                                <div className="user-job">{nowUserInfo.accountInformationPOJO.jobRole || '-'}</div>
                            </div>
                        </div>
                        <div className="r-top userInfo-msgBox">
                            <div>
                                <div className="flex1">
                                    <img src={UserImg1} className="userInfo-pIcon" alt="" />
                                    <span>
                                        {nowUserInfo.accountInformationPOJO.remoteFlag
                                            ? nowUserInfo.accountInformationPOJO.gmt
                                            : nowUserInfo.accountInformationPOJO.location || '-'}
                                    </span>
                                </div>
                                <div className="flex1 mart12">
                                    <img src={UserImg2} className="userInfo-pIcon" alt="" />
                                    <span>
                                        {nowUserInfo.accountInformationPOJO.email
                                            ? nowUserInfo.accountInformationPOJO.email
                                            : nowUserInfo.accountInformationPOJO.emailTwo
                                            ? nowUserInfo.accountInformationPOJO.emailTwo
                                            : '-'}
                                    </span>
                                </div>
                                <div className="flex1 mart12">
                                    <img src={UserImg3} className="userInfo-pIcon" alt="" />
                                    <span>{nowUserInfo.accountInformationPOJO.gender || '-'}</span>
                                </div>
                            </div>
                            <div>
                                <div className="flex1">
                                    <img src={UserImg4} className="userInfo-pIcon" alt="" />
                                    <span>{nowUserInfo.accountInformationPOJO.languageProficientIn || '-'}</span>
                                </div>
                                <div className="flex1 mart12">
                                    <img src={UserImg5} className="userInfo-pIcon" alt="" />
                                    <span>{nowUserInfo.accountInformationPOJO.ethnicity || '-'}</span>
                                </div>{' '}
                                <div className="flex1 mart12">
                                    <img src={UserImg6} className="userInfo-pIcon" alt="" />
                                    <span>{nowUserInfo.accountInformationPOJO.birth || '-'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="userDesc-box">{nowUserInfo.accountInformationPOJO.aboutMe || '-'}</div>
                    </div>
                    {videoList.findIndex(v => v.url) !== -1 ? (
                        <div className="userVideo-list r-left">
                            {videoList.map((item, i) => {
                                return (
                                    <div key={i} className="videoBox-item">
                                        <p className="video-tip">{item.tit}</p>
                                        <div
                                            className="video-img"
                                            style={{
                                                backgroundImage: `url(${item.coverPic})`,
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                            onClick={() => {
                                                setVideoUrl(item.url);
                                                setIsVideoModalOpen(true);
                                            }}
                                        >
                                            <img src={PlayBtn} className="play-imgIcon" alt="" />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="relevantExperience-box" style={{ marginTop: 20 }}>
                        <div style={{ marginBottom: 6 }}>
                            <div className="relevantExperience-tip r-left">
                                Relevant Experience
                                <span className="black-color marLeft">
                                    {relevantExperienceTime ? relevantExperienceTime : '-'}
                                </span>
                            </div>
                            <div className="r-left relevantExperienceList">
                                {relevantExperience && relevantExperience.length ? (
                                    relevantExperience.map((item, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`relevantExperience-item ${
                                                    item.occupationalType !== 'Freelancer' ? 'greenBg' : ''
                                                }`}
                                                style={{ marginBottom: 15 }}
                                            >
                                                <div className="experie-tit r-left oneLineText">
                                                    {item.occupationalType !== 'Freelancer'
                                                        ? item.companyName
                                                        : 'Freelancer'}
                                                    {item.occupationalType !== 'Freelancer' ? (
                                                        <img src={Check} className="jobCheck-icon" alt="" />
                                                    ) : null}
                                                </div>
                                                <div className="experie-job oneLineText">
                                                    {item.jobRoles ? item.jobRoles : '-'}
                                                </div>
                                                <div className="experie-jobTime">
                                                    {item.yearsOfExperience
                                                        ? `${item.yearsOfExperience} ${
                                                              item.yearsOfExperience > 1 ? ' Years ' : ' Year '
                                                          }`
                                                        : ''}
                                                    {item.monthOfExperience
                                                        ? `${item.monthOfExperience} ${
                                                              item.monthOfExperience > 1 ? ' Months' : ' month'
                                                          }`
                                                        : ''}
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div style={{ marginBottom: 15 }}>-</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="relevantExperience-tip r-left">Education</div>
                            <div className="profile-info-pri">
                                {nowUserInfo.accountInformationPOJO.graduation} -{' '}
                                {nowUserInfo.accountInformationPOJO.major}
                            </div>
                        </div>
                        <div style={{ margin: '21px 0 17px 0' }}>
                            <div className="relevantExperience-tip r-left">Skills</div>
                            <div className="profile-info-pri">
                                <div className="r-top" style={{ marginBottom: 5 }}>
                                    <p
                                        className="p-urlTit"
                                        style={{
                                            fontSize: 12,
                                            margin: '0 5px 0 0',
                                            minWidth: 'max-content',
                                            lineHeight: '14px',
                                        }}
                                    >
                                        Hard Skills:
                                    </p>
                                    <div className="profile-info-pri">
                                        {skillList.length
                                            ? skillList.map((val, i) => (
                                                  <span>{`${val.skillName}${
                                                      i !== skillList.length - 1 ? ', ' : ''
                                                  }`}</span>
                                              ))
                                            : '-'}
                                    </div>
                                </div>
                                <div className="r-top">
                                    <p
                                        className="p-urlTit"
                                        style={{
                                            fontSize: 12,
                                            margin: '0 5px 0 0',
                                            minWidth: 'max-content',
                                            lineHeight: '14px',
                                        }}
                                    >
                                        Soft Skills:
                                    </p>
                                    <div className="profile-info-pri">
                                        {softSkillList.length
                                            ? softSkillList.map((val, i) => (
                                                  <span>{`${val.skillName}${
                                                      i !== softSkillList.length - 1 ? ', ' : ''
                                                  }`}</span>
                                              ))
                                            : '-'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="portfolio-box"
                        style={{ display: accountProjectPOJOList.length ? 'block' : 'none' }}
                    >
                        <p className="port-tit">Portfolio</p>
                        <div className="r-left portList-box">
                            {accountProjectPOJOList.map(item => {
                                return (
                                    <div
                                        className="portImg-box"
                                        style={{
                                            border: '1px solid #ccc',
                                            backgroundImage: `url(${item.coverImageUrl})`,
                                            backgroundPosition: 'center center',
                                        }}
                                        onClick={() => {
                                            setProjectInfo(item);
                                            setOpenDrawer(true);
                                        }}
                                    ></div>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        className="relevantExperience-box licenses-box"
                        style={{
                            display: accountCertificatePOJOList.length ? 'block' : 'none',
                            marginTop: 20,
                            paddingBottom: 24,
                        }}
                    >
                        <p className="relevantExperience-tip">Licenses, Certifications and External Assessments</p>
                        {accountCertificatePOJOList.length
                            ? accountCertificatePOJOList.map((item, i) => {
                                  return (
                                      <div className="r-top" key={`cerlicen-${i}`}>
                                          <i className="file-icon"></i>
                                          <div>
                                              <p className="file-name">
                                                  {item.certificateName ? item.certificateName : '-'}
                                              </p>
                                              <p className="file-time">
                                                  {dayjs(item.validPeriodFrom, 'MMM YYYY').format('MMMM YYYY')} -{' '}
                                                  {dayjs(item.validPeriodTo, 'MMM YYYY').format('MMMM YYYY')}
                                              </p>
                                          </div>
                                          {item.certificateUrl ? (
                                              <i
                                                  className="link-icon marLeft"
                                                  onClick={() =>
                                                      window.open(`https://${item.certificateUrl}`, '_target')
                                                  }
                                              ></i>
                                          ) : null}
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            )}

            <PortFolioDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} projectInfo={projectInfo} />
            <VideoPlayModal
                isModalOpen={isVideoModalOpen}
                videoUrl={videoUrl}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
        </div>
    );
}

export default CandidatesDetail;
