import Back from '@/components/back/back';
import { defaultInfo } from '@/typings/allType';
import { Spin, Table, Image } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import api from '@/api/business';
import Pagination from '@/components/pagination';

function BusinessDetail() {
    const [status, setStatus] = useState('showData');
    const [state, setState] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        businessId: '',
        businessName: '',
    });
    const [tableData, setTableData] = useState<any>([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNo: val.pageNo,
            pageSize: state.pageSize,
            businessId: val.businessId,
        };
        setStatus('loading');
        api.getBusinessTeamList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.list.length > 0) {
                const list = data.list.map(item => {
                    const fullName = `${item.firstName ? item.firstName : ''}${
                        item.middleName ? ' ' + item.middleName : ''
                    }${item.lastName ? ' ' + item.lastName : ''}`;
                    return Object.assign(item, {
                        fullName: fullName,
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.totalRecord);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.totalRecord);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
    };
    useEffect(() => {
        let info: any = window.sessionStorage.getItem('businessInfo') || '{}';
        if (info) {
            info = JSON.parse(info);
            getList({ ...state, businessId: info.businessId });
            setState({ ...state, businessId: info.businessId, businessName: info.businessName });
        }
    }, []);
    const columns = [
        {
            title: 'Name',
            dataIndex: '',
            with: 200,
            key: 'displayName',
            render: val => (
                <div className="cell-txt r-left">
                    <Image
                        width={32}
                        height={32}
                        preview={false}
                        style={{ borderRadius: '50%', minWidth: 32 }}
                        src={val.avatarUrl}
                        fallback={require('@/assets/img/user-default.png')}
                    />
                    <span style={{ marginLeft: 10 }}>{val.fullName ?? '-'}</span>
                </div>
            ),
        },
        {
            title: 'Email Address',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val?.email || '-'}</div>,
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Role',
            width: 190,
            dataIndex: 'role',
            key: 'role',
        },
    ];
    return (
        <div className="c-pageContent">
            <div>
                <Back />
            </div>
            <h2>{state.businessName || '-'}</h2>
            <p className="detailInfo-tip">Team member: {totalRecord || 0}</p>
            <div className="detailTable-box">
                <>
                    {status === 'loading' ? (
                        <div className="r-center" style={{ padding: '80px 0' }}>
                            <Spin />
                        </div>
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            // rowKey={val => val.workoutRewardsId}
                            rowHoverable={false}
                            className="r-table_mini click_row"
                            rowClassName={() => 'r-tableRow-mini'}
                            pagination={false}
                        />
                    )}
                </>
            </div>
            {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
}

export default BusinessDetail;
