import React, { useState } from 'react';
import { Button, Input, Popover, Table } from 'antd';
import Back from '@/components/back/back';
import PageFilter from '@/components/page-filter';
import Pagination from '@/components/pagination';
import { defaultInfo } from '@/typings/allType';
import DefaultModal from '@/components/modal/default-modal';
import AlertModal from '@/components/modal/alert-modal';
import { useNavigate } from 'react-router-dom';
function JobRoleDetail() {
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [totalRecord, setTotalRecord] = useState(100);
    const handlePageChange = prop => {
        console.log(prop);
    };
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
        status: '-1',
    });
    const [modalText, setModalText] = useState('Are you sure you want to remove this job role?');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isJobRoleModalOpen, setJobRoleIsModalOpen] = useState(false);
    const [jobRoleModalType, setJobRoleModalType] = useState('add');
    const opeationBtnClcik = (type: number, val?: defaultInfo, i?: number) => {
        if (i !== undefined) {
            tableData[i].popoverOpen = false;
            setTableData([...tableData]);
        }
        if (type === 1) {
            setJobRoleIsModalOpen(true);
            setJobRoleModalType('edit');
            return;
        }
        setIsModalOpen(true);
    };
    const [tableData, setTableData] = useState<any>([
        { popoverOpen: false },
        { popoverOpen: false },
        { popoverOpen: false },
        { popoverOpen: false },
    ]);
    const columns = [
        {
            title: 'Job Role Name',
            dataIndex: '',
            key: 'displayName',
            render: val => (
                <div className="cell-txt r-left">
                    <i className="img-icon"></i>
                    {val.displayName ?? 'Top Speed Headhunting'}
                </div>
            ),
        },
        {
            title: 'Associated Job',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ?? 'PM, Senior Product Manager'}</div>,
        },
        {
            title: '',
            dataIndex: '',
            key: 'prizeId',
            width: 60,
            render: (val, info, i) => (
                <div className="cell-txt r-end">
                    <Popover
                        placement="bottomRight"
                        title={''}
                        trigger="click"
                        open={val.popoverOpen}
                        onOpenChange={val => {
                            tableData[i].popoverOpen = val;
                            setTableData([...tableData]);
                        }}
                        content={
                            <div className="tableOpeation-box">
                                <div
                                    className={`tb_item`}
                                    onClick={() => {
                                        opeationBtnClcik(1, val, i);
                                    }}
                                >
                                    Edit
                                </div>
                                <div
                                    className="tb_item danger"
                                    onClick={() => {
                                        opeationBtnClcik(2, val, i);
                                    }}
                                >
                                    Remove
                                </div>
                            </div>
                        }
                        arrow={false}
                    >
                        <i
                            className={`more_icon ${val.popoverOpen ? 'more_iconSeled' : ''}`}
                            onClick={() => {
                                tableData[i].popoverOpen = true;
                                setTableData([...tableData]);
                            }}
                        ></i>
                    </Popover>
                </div>
            ),
        },
    ];
    return (
        <div className="c-pageContent-jobRole">
            <div>
                <Back />
            </div>
            <h2>Design</h2>
            <div>
                <PageFilter
                    searchPlaceholder="Name"
                    type={1}
                    children={
                        <div className="top-opBtnBox marLeft r-left">
                            <div className="roleRightNum-box">
                                <Button
                                    className="r-primaryNoBg_btn jobNobg-btn marr20"
                                    onClick={() => navigate('/customer-job-role-detail')}
                                >
                                    Custom Job Role
                                </Button>
                                <i className="roleNum-box">5</i>
                            </div>
                            <Button
                                className="r-primaryNoBg_btn jobNobg-btn"
                                onClick={() => {
                                    setJobRoleIsModalOpen(true);
                                    setJobRoleModalType('add');
                                }}
                            >
                                Add New Job Role
                            </Button>
                        </div>
                    }
                />
            </div>
            <div className="detailTable-box">
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowHoverable={false}
                    className="r-table click_row"
                    rowClassName={() => 'r-tableRow'}
                    pagination={false}
                />
                {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                    <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                ) : (
                    <></>
                )}
            </div>
            <AlertModal
                message={modalText}
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={isModalOpen}
                handleOk={() => setIsModalOpen(false)}
                handleCancel={() => setIsModalOpen(false)}
            />
            <DefaultModal
                title={`${jobRoleModalType == 'add' ? 'Add New ' : 'Edit '}Job Role`}
                width={700}
                isModalOpen={isJobRoleModalOpen}
                handleOk={() => setJobRoleIsModalOpen(false)}
                handleCancel={() => setJobRoleIsModalOpen(false)}
                children={
                    <div>
                        <div style={{ margin: '25px 0 60px 0' }}>
                            <p className="form-tip">Job Role Nmae</p>
                            <Input
                                className={`r-input`}
                                maxLength={150}
                                autoComplete="off"
                                placeholder={'Job Role Nmae'}
                            />
                        </div>
                        <Button className="r-primary_btn" onClick={() => setJobRoleIsModalOpen(false)}>
                            Save
                        </Button>
                    </div>
                }
            />
        </div>
    );
}

export default JobRoleDetail;
