import React, { useEffect, useState } from 'react';
import './style/index.scss';
import PageFilter from '@/components/page-filter';
import Pagination from '@/components/pagination';
import { useNavigate } from 'react-router-dom';
import { Popover, Spin, Table } from 'antd';
import { defaultInfo } from '@/typings/allType';
import AlertModal from '@/components/modal/alert-modal';
import api from '@/api/business';
import dayjs from 'dayjs';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const Index = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [isSearch, setIsSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalText, setModalText] = useState('Are you sure you want to suspend this account?');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const [opType, setOptype] = useState({ businessId: '', type: 2 });
    const [opLoading, setOpLoading] = useState(false);
    const confirmOp = () => {
        setOpLoading(true);
        if (opType.type === 2) {
            // disabled
            api.setBusinessSuspened({ businessId: opType.businessId }, res => {
                setOpLoading(false);
                const { data, code, message } = res;
                if (code === 200) {
                    getList({ ...state });
                    setIsModalOpen(false);
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        // delete
        api.deleteBusiness({ businessId: opType.businessId }, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                getList({ ...state });
                setIsModalOpen(false);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [state, setState] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        time: null,
        beginTime: '' as any,
        endTime: '' as any,
        status: 'all',
    });
    const opeationBtnClcik = (type: number, val: defaultInfo, i?: number) => {
        if (type === 1) {
            window.sessionStorage.setItem('businessInfo', JSON.stringify(val));
            navigate('/business-detail');
            return;
        }
        if (type === 2 && val.disabledTime) {
            //启用
            api.setBusinessActive({ businessId: val.businessId }, res => {
                const { data, code, message } = res;
                if (code === 200) {
                    getList({ ...state });
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        setOptype({ businessId: val.businessId, type });
        setModalText(
            type === 2
                ? 'Are you sure you want to suspend this account?  After the account is suspended, all team members of this business will not be able to log in.'
                : 'Are you sure you want to remove this account? After the account is removed, all data for the account will be deleted and the operation cannot be undone.'
        );
        setIsModalOpen(true);
    };
    const [tableData, setTableData] = useState<any>([]);
    const columns = [
        {
            title: 'Business Name',
            dataIndex: '',
            key: 'displayName',
            render: val => <div className="cell-txt r-left">{val.businessName || '-'}</div>,
        },
        {
            title: 'Email Address',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email || '-'}</div>,
        },
        {
            title: 'Team size',
            dataIndex: '',
            width: 120,
            key: 'mobileNumber',
            render: val => <div className="cell-txt">{val.teamSize || '0'}</div>,
        },
        {
            title: 'Job',
            dataIndex: '',
            width: 100,
            key: 'subscription',
            render: val => <div className="cell-txt">{val.jobNumber || '0'}</div>,
        },
        {
            title: 'Subscription',
            dataIndex: '',
            key: 'subscription',
            render: val => <div className="cell-txt">{val.subscription || '-'}</div>,
        },
        {
            title: 'Registration Date',
            key: 'createTime',
            width: 200,
            render: val => <div className="cell-txt">{val.createTime || '-'}</div>,
        },
        {
            title: 'Status',
            key: 'status',
            width: 140,
            render: val => <div className="cell-txt">{val.status || 'Active'}</div>,
        },
        {
            title: '',
            dataIndex: '',
            key: 'prizeId',
            width: 60,
            render: (val, info, i) => (
                <div className="cell-txt r-end">
                    <Popover
                        placement="bottomRight"
                        title={''}
                        trigger="hover"
                        content={
                            <div className="tableOpeation-box">
                                <div
                                    className={`tb_item`}
                                    onClick={() => {
                                        opeationBtnClcik(1, val, i);
                                    }}
                                >
                                    View details
                                </div>
                                <div
                                    className={`tb_item ${!val.disabledTime ? 'danger' : 'success'}`}
                                    onClick={() => {
                                        opeationBtnClcik(2, val, i);
                                    }}
                                >
                                    {!val.disabledTime ? 'Suspend account' : 'Enable Account'}
                                </div>
                                <div
                                    className="tb_item danger"
                                    onClick={() => {
                                        opeationBtnClcik(3, val, i);
                                    }}
                                >
                                    Remove account
                                </div>
                            </div>
                        }
                        arrow={false}
                    >
                        <i className={`more_icon ${val.popoverOpen ? 'more_iconSeled' : ''}`}></i>
                    </Popover>
                </div>
            ),
        },
    ];
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNo: val.pageNo,
            pageSize: state.pageSize,
            beginTime: val.beginTime ? dayjs(`${val.beginTime} 00:00:00`).valueOf() / 1000 : 0,
            endTime: val.endTime ? dayjs(`${val.endTime} 23:59:59`).valueOf() / 1000 : 9999999999,
            keyWord: val.search,
            status: val.status === '-1' ? '' : val.status,
        };
        setStatus('loading');
        api.getBusinessList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.list.length > 0) {
                const list = data.list.map(item => {
                    return Object.assign(item, {
                        createTime: item.createTime ? window.format.foramtTimeDMY_easy(+item.createTime * 1000) : '-',
                        mobileNumber: item.mobileNumber ? window.format.formatNumber(item.mobileNumber) : '-',
                        status: item.disabledTime ? 'Suspended' : 'Active',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.totalRecord);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.totalRecord);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    useEffect(() => {
        getList({ ...state });
    }, []);
    return (
        <div className="c-pageContent">
            <h1>Business</h1>
            <>
                {status === 'loading' ? (
                    <div className="r-center" style={{ padding: '80px 0' }}>
                        <Spin />
                    </div>
                ) : (
                    <>
                        <div className="pad-top">
                            <PageFilter state={state} setState={setState} getList={getList} />
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            rowKey={val => val.businessId}
                            rowHoverable={false}
                            className="r-table click_row"
                            rowClassName={() => 'r-tableRow'}
                            pagination={false}
                        />
                    </>
                )}
            </>
            {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
            <AlertModal
                message={modalText}
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={isModalOpen}
                loading={opLoading}
                handleOk={() => confirmOp()}
                handleCancel={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default Index;
