import { Button, Modal } from 'antd';
import './index.scss';
function AlertModal({ isModalOpen, handleOk, handleCancel, message, cancelText, confirmText, loading }: any) {
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            closeIcon={null}
            footer={''}
            width={560}
            title={null}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <p className="primary-modal-tip" style={{ marginBottom: 50 }}>
                {message}
            </p>
            <div className="r-center">
                <Button className="r-primary_btn cancel_btn" style={{ marginRight: 20 }} onClick={() => handleCancel()}>
                    {cancelText}
                </Button>
                <Button className="r-primary_btn danger_btn" loading={loading ?? false} onClick={() => handleOk()}>
                    {confirmText}
                </Button>
            </div>
        </Modal>
    );
}

export default AlertModal;
