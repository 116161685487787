import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import CodeInput from '../code-input/index';
function ForgetSendEmailModal({ isModalOpen, handleOk, handleCancel, loading }) {
    const [email, setEmail] = useState('');
    const confirm = () => {
        if (!window.utils.isEmail(email)) {
            window.utils.alert('error', 'Please enter a valid email address');
            return;
        }
        handleOk(email);
        // setEmail('');
    };
    useEffect(() => {
        if (!isModalOpen) {
            setEmail('');
        }
    }, [isModalOpen]);
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={530}
            title={
                <div className="modal-top-title" style={{ marginBottom: 25 }}>
                    Forgot Password
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <p className="mini-tip">
                Enter the email associated with your account and we will send you an email verification code
            </p>
            <div style={{ margin: '25px 0 60px 0' }}>
                <p className="form-tip">Email Address</p>
                <Input
                    className={`r-input`}
                    value={email}
                    onInput={(e: any) => setEmail(e.target.value)}
                    maxLength={150}
                    autoComplete="off"
                    placeholder={'Email address'}
                />
            </div>
            <Button className="r-primary_btn" disabled={!email} loading={loading} onClick={() => confirm()}>
                Send
            </Button>
        </Modal>
    );
}

export default ForgetSendEmailModal;
