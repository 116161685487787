import './index.scss';
import { useNavigate } from 'react-router-dom';
function Back({ uri }: { uri?: string }) {
    const navigate = useNavigate();
    const backPage = () => {
        if (uri) {
            navigate(uri);
            return;
        }
        navigate(-1);
    };
    return (
        <div className="r-left back-box" onClick={() => backPage()}>
            <i className="back-icon"></i>
            Back
        </div>
    );
}

export default Back;
