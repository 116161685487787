import axios from '../utils/index';
import { Pat } from '@/typings/request';
const businessApi = {
    getBusinessList(data: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/admin/business-list/${data.status}/${data.beginTime}/${data.endTime}/${data.pageNo}/${data.pageSize}`,
                data
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setBusinessActive(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/admin/business-Active/${data.businessId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setBusinessSuspened(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/admin/business-Suspended/${data.businessId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteBusiness(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/admin/business-delete/${data.businessId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBusinessTeamList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/business-team-list/${data.businessId}/${data.pageNo}/${data.pageSize}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default businessApi;
