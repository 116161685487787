import React, { useEffect, useState } from 'react';
import './style/index.scss';
import PageFilter from '@/components/page-filter';
import Pagination from '@/components/pagination';
import { useNavigate } from 'react-router-dom';
import { Popover, Table, Image, Spin } from 'antd';
import { defaultInfo } from '@/typings/allType';
import AlertModal from '@/components/modal/alert-modal';
import api from '@/api/candidates';
import dayjs from 'dayjs';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import useLoading from '@/hook/useLoading';
const Candidates = () => {
    const navigate = useNavigate();
    const { setLoadingStatus } = useLoading();
    const [status, setStatus] = useState('showData');
    const [isSearch, setIsSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalText, setModalText] = useState('Are you sure you want to suspend this account?');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [opType, setOptype] = useState({ accountId: '', type: 2 });
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const [state, setState] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        time: null,
        beginTime: '' as any,
        endTime: '' as any,
        status: 'all',
    });
    const opeationBtnClcik = (type: number, val: defaultInfo, i?: number) => {
        if (type === 1) {
            window.sessionStorage.setItem('candidateInfo', JSON.stringify(val));
            navigate('/candidates-detail');
            return;
        }
        if (type === 2 && val.status !== 'Active') {
            //启用
            // setLoadingStatus(true);
            api.setAccountActive({ accountId: val.accountId }, res => {
                // setLoadingStatus(false);
                const { data, code, message } = res;
                if (code === 200) {
                    getList({ ...state });
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        setOptype({ accountId: val.accountId, type });
        setModalText(
            type === 2
                ? 'Are you sure to suspend this account?  After the account is suspended, the user cannot log in.'
                : 'Are you sure you want to remove this account? After the account is removed, all data for the account will be deleted and the operation cannot be undone.'
        );
        setIsModalOpen(true);
    };
    const confirmOpeation = () => {
        setLoading(true);
        if (opType.type === 2) {
            // disabled
            api.setAccountSuspened({ accountId: opType.accountId }, res => {
                setLoading(false);
                const { data, code, message } = res;
                if (code === 200) {
                    getList({ ...state });
                    setIsModalOpen(false);
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        // delete
        api.deleteAccount({ accountId: opType.accountId }, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                getList({ ...state });
                setIsModalOpen(false);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [tableData, setTableData] = useState<any>([]);
    const columns = [
        {
            title: 'Full Name',
            key: 'displayName',
            render: val => (
                <div className="cell-txt r-left">
                    <Image
                        width={32}
                        height={32}
                        preview={false}
                        style={{ borderRadius: '50%', minWidth: 32 }}
                        src={val.profilePhoto}
                        fallback={require('@/assets/img/user-default.png')}
                    />
                    <span style={{ marginLeft: 10 }}>{val.displayName || '-'}</span>
                </div>
            ),
        },
        {
            title: 'Email',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ? val.email : val.emailTwo ? val.emailTwo : '-'}</div>,
        },
        {
            title: 'Job Role',
            key: 'jobRole',
            render: val => <div className="cell-txt">{val.jobRole || '-'}</div>,
        },
        {
            title: 'Month/Year of Birth',
            key: 'birth',
            render: val => <div className="cell-txt">{val.birth || '-'}</div>,
        },
        {
            title: 'Gender',
            key: 'gender',
            width: 120,
            render: val => <div className="cell-txt">{val.gender ? val.gender : '-'}</div>,
        },
        {
            title: 'Registration Date',
            key: 'createTime',
            width: 200,
            render: val => <div className="cell-txt">{val.createTime || '-'}</div>,
        },
        {
            title: 'Status',
            key: 'createTime',
            width: 140,
            render: val => (
                <div className="cell-txt" style={{ color: val.status === 'Active' ? '#222222' : '#F87171' }}>
                    {val.status || 'Active'}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'prizeId',
            width: 60,
            render: (val, info, i) => (
                <div className="cell-txt r-end">
                    <Popover
                        placement="bottomRight"
                        title={''}
                        trigger="hover"
                        content={
                            <div className="tableOpeation-box">
                                <div
                                    className={`tb_item`}
                                    onClick={() => {
                                        opeationBtnClcik(1, val, i);
                                    }}
                                >
                                    View details
                                </div>
                                <div
                                    className={`tb_item ${val.status === 'Active' ? 'danger' : 'success'}`}
                                    onClick={() => {
                                        opeationBtnClcik(2, val, i);
                                    }}
                                >
                                    {val.status === 'Active' ? 'Suspend account' : 'Active'}
                                </div>
                                <div
                                    className="tb_item danger"
                                    onClick={() => {
                                        opeationBtnClcik(3, val, i);
                                    }}
                                >
                                    Remove account
                                </div>
                            </div>
                        }
                        arrow={false}
                    >
                        <i className={`more_icon ${val.popoverOpen ? 'more_iconSeled' : ''}`}></i>
                    </Popover>
                </div>
            ),
        },
    ];
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNo: val.pageNo,
            pageSize: state.pageSize,
            beginTime: val.beginTime ? dayjs(`${val.beginTime} 00:00:00`).valueOf() / 1000 : 0,
            endTime: val.endTime ? dayjs(`${val.endTime} 23:59:59`).valueOf() / 1000 : 9999999999,
            keyWord: val.search,
            status: val.status === '-1' ? '' : val.status,
        };
        setStatus('loading');
        api.getBusinessList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.list.length > 0) {
                const list = data.list.map(item => {
                    item = Object.assign(item, { ...item.accountInformationPOJO });
                    const fullName = `${item.firstName ? item.firstName : ''}${
                        item.middleName ? ' ' + item.middleName : ''
                    }${item.lastName ? ' ' + item.lastName : ''}`;
                    return Object.assign(item, {
                        createTime: item.createTime ? window.format.foramtTimeDMY(+item.createTime * 1000) : '-',
                        status: item.disabledTime ? 'Suspended' : 'Active',
                        displayName: fullName ? fullName : '-',
                        namelabel: fullName ? fullName : '-',
                        birth: item.birth ? dayjs(item.birth).format('MMM YYYY') : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.totalRecord);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.totalRecord);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    useEffect(() => {
        getList({ ...state });
    }, []);
    return (
        <div className="c-pageContent">
            <h1>Candidates</h1>

            <>
                {status === 'loading' ? (
                    <div className="r-center" style={{ padding: '80px 0' }}>
                        <Spin style={{ position: 'static' }} />
                    </div>
                ) : (
                    <>
                        <div className="pad-top">
                            <PageFilter
                                searchPlaceholder="Name/Email/Role"
                                state={state}
                                setState={setState}
                                getList={getList}
                            />
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            rowKey={val => val.accountId}
                            rowHoverable={false}
                            className="r-table click_row"
                            rowClassName={() => 'r-tableRow'}
                            pagination={false}
                        />
                    </>
                )}
            </>
            {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
            <AlertModal
                message={modalText}
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={isModalOpen}
                handleOk={() => confirmOpeation()}
                loading={loading}
                handleCancel={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default Candidates;
