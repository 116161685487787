import { Button, Form, FormProps, Input, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '@/api/login';
import JSEncrypt from 'jsencrypt';
import './index.scss';
function ResetPassword() {
    const passwordEncrypt = new JSEncrypt();
    const [eyeOpen, setEyeOpen] = useState(false);
    const [eyeOpen1, setEyeOpen1] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        password: '',
        rePassword: '',
    });
    const [sendLoading, setSendLoading] = useState(false);
    const handleValidate = async () => {
        try {
            const values = await form.validateFields();
            console.log('Validation Success:', values);
            if (state.password !== state.rePassword) {
                message.error('The 2 passwords entered are not the same');
                setState({ password: '', rePassword: '' });
                form.setFieldsValue({ password: '', rePassword: '' });
                return;
            }
            setSendLoading(true);
            api.getPublicKey(res => {
                const { code, data } = res;
                if (code === 200 && data) {
                    passwordEncrypt.setPublicKey(data);
                    api.resetPassword(
                        {
                            code: window.sessionStorage.getItem('code'),
                            email: window.sessionStorage.getItem('email'),
                            password: passwordEncrypt.encrypt(state.password),
                        },
                        res => {
                            setSendLoading(false);
                            const { code, data } = res;
                            if (code === 200) {
                                navigate('/reset-success');
                            } else {
                                window.utils.alert('error', res.message);
                            }
                        }
                    );
                } else {
                    message.error(res.message);
                    setSendLoading(false);
                }
            });
        } catch (error) {
            setSendLoading(false);
            console.log('Validation Failed:', error);
        }
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    return (
        <div className="login-main r-center">
            <div className="center-box">
                <div className="login-formBox">
                    <h2>Reset Password</h2>
                    <p className="tit-tip">Create a new password below.</p>
                    <Form
                        form={form}
                        name="basic-resetPassword"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        validateTrigger="onBlur"
                        className="login-form"
                    >
                        <Form.Item label="Type new password" name="password" rules={[{ validator: validatePassword }]}>
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={'Enter 8-12 digit password'}
                                value={state.password}
                                onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                type={eyeOpen ? 'text' : 'password'}
                                suffix={
                                    eyeOpen ? (
                                        <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: 30 }}
                            label="Re-type new password"
                            name="rePassword"
                            rules={[{ validator: validatePassword }]}
                        >
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={'Re-enter the 8-12 digit password'}
                                value={state.rePassword}
                                onChange={(e: any) => setState({ ...state, rePassword: e.target.value })}
                                type={eyeOpen1 ? 'text' : 'password'}
                                suffix={
                                    eyeOpen1 ? (
                                        <i className="eye-open" onClick={() => setEyeOpen1(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen1(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                    </Form>
                    <p className="line-box"></p>
                    <Button
                        style={{ margin: '30px 0 0 0' }}
                        className="r-primary_btn"
                        disabled={!state.rePassword || !state.password}
                        onClick={() => handleValidate()}
                        loading={sendLoading}
                    >
                        Save new password
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
