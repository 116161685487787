import { Drawer, Image } from 'antd';
import './index.scss';
function PortFolioDrawer({ open, onClose, projectInfo }: any) {
    return (
        <div>
            <Drawer
                rootClassName="portFolioDrawer-box"
                placement={'bottom'}
                closable={false}
                keyboard={false}
                maskClosable={false}
                onClose={onClose}
                open={open}
                height="91%"
                key={'bottom'}
            >
                <i className="close-icon-drawer" onClick={() => onClose()}></i>
                <div className="r-left" style={{ marginBottom: 24 }}>
                    <Image
                        width={52}
                        height={52}
                        preview={false}
                        style={{ borderRadius: 12 }}
                        src={projectInfo?.coverImageUrl}
                        fallback={require('@/assets/img/default-img.png')}
                    />
                    <span className="portFolio-tit">{projectInfo?.projectName ? projectInfo.projectName : '-'}</span>
                </div>
                {projectInfo?.accountProjectFilePOJOList
                    ? projectInfo.accountProjectFilePOJOList.map((item, i) => {
                          return (
                              <div>
                                  {item.fileType === 'text' ? (
                                      <div className="textBox marb24" style={{ whiteSpace: 'pre-line' }}>
                                          {item.fileText ? item.fileText : '-'}
                                      </div>
                                  ) : item.fileType === 'image' ? (
                                      <div className="imgBox marb24 r-center">
                                          <img
                                              style={{ maxWidth: '100%', minHeight: 360 }}
                                              src={item.fileText ? item.fileText : ''}
                                              alt=""
                                          />
                                      </div>
                                  ) : (
                                      <video
                                          className="textBox marb24"
                                          controls
                                          src={item.fileText ? item.fileText : require('@/assets/img/test1.mp4')}
                                      />
                                  )}
                              </div>
                          );
                      })
                    : ''}
                {projectInfo?.externalProjectUrl ? (
                    <div className="r-left">
                        <i className="link-icon-draw"></i>
                        <a className="footerLink" href={`https://${projectInfo.externalProjectUrl}`} target="_blank">
                            {projectInfo.externalProjectUrl}
                        </a>
                    </div>
                ) : null}
            </Drawer>
        </div>
    );
}

export default PortFolioDrawer;
