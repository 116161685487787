import React, { useState } from 'react';
import { Button, Input, Select, Table } from 'antd';
import Back from '@/components/back/back';
import Pagination from '@/components/pagination';
import { defaultInfo } from '@/typings/allType';
import DefaultModal from '@/components/modal/default-modal';
import './style/index.scss';
function CustomerJobRoleDetail() {
    const [status, setStatus] = useState('showData');
    const [totalRecord, setTotalRecord] = useState(100);
    const handlePageChange = prop => {
        console.log(prop);
    };
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
        status: '-1',
    });
    const [jobRoleInfo, setJobRoleInfo] = useState({
        name: '',
        industry: '',
        mode: 0,
        include: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openInclude = val => {
        setIsModalOpen(true);
    };
    const [tableData, setTableData] = useState<any>([
        { popoverOpen: false },
        { popoverOpen: false },
        { popoverOpen: false },
        { popoverOpen: false },
    ]);
    const columns = [
        {
            title: 'Job Role Name',
            dataIndex: '',
            key: 'displayName',
            render: val => (
                <div className="cell-txt r-left">
                    <i className="img-icon"></i>
                    {val.displayName ?? 'Top Speed Headhunting'}
                </div>
            ),
        },
        {
            title: '',
            width: 170,
            dataIndex: '',
            key: 'email',
            render: val => (
                <div className="cell-txt cell-minBtn r-center" onClick={val => openInclude(val)}>
                    Include
                </div>
            ),
        },
    ];
    return (
        <div className="c-pageContent-jobRole">
            <div>
                <Back />
            </div>
            <h2 style={{ margin: '40px 0' }}>Custom Job Role</h2>
            <div className="detailTable-box">
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowHoverable={false}
                    className="r-table click_row"
                    rowClassName={() => 'r-tableRow'}
                    pagination={false}
                />
                {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                    <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                ) : (
                    <></>
                )}
            </div>
            <DefaultModal
                title="Include Job Role"
                width={700}
                isModalOpen={isModalOpen}
                handleOk={() => setIsModalOpen(false)}
                handleCancel={() => setIsModalOpen(false)}
                children={
                    <div>
                        <div style={{ margin: '25px 0 60px 0' }}>
                            <p className="form-tip">Job Role Name</p>
                            <Input
                                className={`r-input`}
                                maxLength={150}
                                autoComplete="off"
                                placeholder={'Job Role Name'}
                            />
                            <p className="form-tip mart20">Industry</p>
                            <Select
                                style={{ width: '100%', height: 60 }}
                                className="r-select"
                                value={state.status ? state.status : void 0}
                                placeholder="Select"
                                suffixIcon={<i className="sel_right_icon"></i>}
                                onChange={val => {
                                    setState({ ...state, status: val });
                                    console.log('change');
                                }}
                                options={[
                                    { value: '-1', label: 'All status' },
                                    { value: '3', label: 'Gold' },
                                    { value: '2', label: 'Silver' },
                                    { value: '1', label: 'Bronze' },
                                    { value: '0', label: 'Freemium' },
                                ]}
                            />
                            <p className="form-tip mart20">Mode</p>
                            <div className="r-left">
                                <div className="r-left" onClick={() => setJobRoleInfo({ ...jobRoleInfo, mode: 0 })}>
                                    <i className={`radio_icon ${!jobRoleInfo.mode ? 'radioed_icon' : ''}`}></i>
                                    <span className="r-radioText">Include</span>
                                </div>
                                <div
                                    className="r-left cursorPointer"
                                    style={{ marginLeft: 240 }}
                                    onClick={() => setJobRoleInfo({ ...jobRoleInfo, mode: 1 })}
                                >
                                    <i className={`radio_icon ${jobRoleInfo.mode ? 'radioed_icon' : ''}`}></i>
                                    <span className="r-radioText">Create new</span>
                                </div>
                            </div>
                            {!jobRoleInfo.mode ? (
                                <Select
                                    style={{ width: '100%', height: 60 }}
                                    className="r-select mart20"
                                    value={state.status ? state.status : void 0}
                                    placeholder="Select"
                                    suffixIcon={<i className="sel_right_icon"></i>}
                                    onChange={val => {
                                        setState({ ...state, status: val });
                                        console.log('change');
                                    }}
                                    options={[
                                        { value: '-1', label: 'All status' },
                                        { value: '3', label: 'Gold' },
                                        { value: '2', label: 'Silver' },
                                        { value: '1', label: 'Bronze' },
                                        { value: '0', label: 'Freemium' },
                                    ]}
                                />
                            ) : null}
                        </div>
                        <Button className="r-primary_btn" onClick={() => setIsModalOpen(false)}>
                            Save
                        </Button>
                    </div>
                }
            />
        </div>
    );
}

export default CustomerJobRoleDetail;
