import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import api from '@/api/login';

interface FeedbackContextType {
    feedbackCount: number;
    refreshFeedbackCount: () => void;
}

const FeedbackContext = createContext<FeedbackContextType | undefined>(undefined);

export const FeedbackProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [feedbackCount, setFeedBackCount] = useState<number>(0);
    const getFeedCount = () => {
        const token = sessionStorage.getItem('admin-satoken');
        if (!token) return; // 没有 token 时不执行请求
        api.getUnReadFeedbackCount(res => {
            const { data, code, message } = res;
            if (code === 200) {
                setFeedBackCount(data);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    useEffect(() => {
        getFeedCount();

        const interval = setInterval(() => {
            getFeedCount();
        }, 60000);

        // 监听 token 变化并调用请求
        const handleStorageChange = () => {
            if (sessionStorage.getItem('admin-satoken')) {
                getFeedCount();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            clearInterval(interval);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <FeedbackContext.Provider value={{ feedbackCount, refreshFeedbackCount: getFeedCount }}>
            {children}
        </FeedbackContext.Provider>
    );
};

export const useFeedback = () => {
    const context = useContext(FeedbackContext);
    if (context === undefined) {
        throw new Error('useFeedback must be used within a FeedbackProvider');
    }
    return context;
};
