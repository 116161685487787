import axios from '../utils/index';
import { Pat } from '@/typings/request';
const candidatesApi = {
    getBusinessList(data: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/admin/account-list/${data.status}/${data.beginTime}/${data.endTime}/${data.pageNo}/${data.pageSize}`,
                data
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setAccountActive(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/admin/account-Active/${data.accountId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setAccountSuspened(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/admin/account-Suspended/${data.accountId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteAccount(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/admin/account-delete/${data.accountId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getAccountInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/admin/account-profile/${data.accountId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default candidatesApi;
