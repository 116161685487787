import axios from '../utils/index';
import { Pat } from '@/typings/request';
const loginApi = {
    getPublicKey(callback: (T: any) => void) {
        axios
            .get(`/v1/public/rsa/get-publicKey`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    login(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/admin-login`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    logOut(callback: (T: any) => void) {
        axios
            .post(`/v1/admin/admin-logout`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    sendEmailCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/admin-forgotPassword-send-email`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    verifyEmailCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/admin-forgotPassword-check`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    resetPassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/admin-password-reset`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUnReadFeedbackCount(callback: (T: any) => void) {
        axios
            .get(`/v1/admin/feedback-unReadCount`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
