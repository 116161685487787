import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import Back from '@/components/back/back';
import PageFilter from '@/components/page-filter';
import Pagination from '@/components/pagination';
import { defaultInfo } from '@/typings/allType';
import { useNavigate } from 'react-router-dom';
import './style/index.scss';
import api from '@/api/subscription';
import dayjs from 'dayjs';
function SubscriptionRecord() {
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [isSearch, setIsSearch] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const handlePageChange = prop => {
        getList({ ...state, pageNo: prop });
    };
    const [state, setState] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        beginTime: '' as any,
        endTime: '' as any,
        status: 'all',
    });
    const [tableData, setTableData] = useState<any>([]);
    const columns = [
        {
            title: 'Business Name',
            dataIndex: '',
            key: 'displayName',
            render: val => (
                <div className="cell-txt r-left">
                    <i className="img-icon"></i>
                    {val.displayName ?? 'Top Speed Headhunting'}
                </div>
            ),
        },
        {
            title: 'Type',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ?? 'Monthly'}</div>,
        },
        {
            title: 'Plan Name',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ?? 'Boost'}</div>,
        },
        {
            title: 'Amount',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ?? '$800.00'}</div>,
        },
        {
            title: 'Subscription Date',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ?? '1 Apr 1998'}</div>,
        },
    ];
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNo: val.pageNo,
            pageSize: state.pageSize,
            beginTime: val.beginTime ? dayjs(`${val.beginTime} 00:00:00`).valueOf() : 0,
            endTime: val.endTime ? dayjs(`${val.endTime} 23:59:59`).valueOf() : 9999999999,
            keyWord: val.search,
            status: val.status === '-1' ? '' : val.status,
        };
        setStatus('loading');
        api.getSubscriptionRecordList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.list.length > 0) {
                const list = data.list.map(item => {
                    const fullName = `${item.firstName ? item.firstName : ''}${
                        item.middleName ? ' ' + item.middleName : ''
                    }${item.lastName ? ' ' + item.lastName : ''}`;
                    return Object.assign(item, {
                        createTime: item.createTime ? window.format.foramtTimeDMY(+item.createTime * 1000) : '-',
                        status: item.disabledTime ? 'Suspended' : 'Active',
                        displayName: item.businessName ? item.businessName : fullName ? fullName : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.totalRecord);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.totalRecord);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    useEffect(() => {
        getList({ ...state });
    }, []);
    return (
        <div className="c-pageContent-subscription">
            <div>
                <Back />
            </div>
            <h2>Subscription Record</h2>
            <div>
                <PageFilter searchPlaceholder="Name" type={-1} state={state} setState={setState} getList={getList} />
            </div>
            <div className="detailTable-box">
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowHoverable={false}
                    className="r-table click_row"
                    rowClassName={() => 'r-tableRow'}
                    pagination={false}
                />
                {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                    <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default SubscriptionRecord;
