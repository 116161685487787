import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import CodeInput from '../code-input/index';
function VerificationCodeDialog({ isModalOpen, handleOk, handleCancel, isSending, countdown, resendClick }) {
    const [codeArr, setCodeArr] = useState(['', '', '', '']);
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={530}
            title={
                <div className="modal-top-title" style={{ marginBottom: 30 }}>
                    Verification Code
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <p className="mini-tip">Please enter the 6 digit Verification Code sent to your email address.</p>
            <div style={{ margin: '20px 0 30px 0' }}>
                <CodeInput setCodeArr={setCodeArr}></CodeInput>
            </div>
            <Button
                className="r-primary_btn"
                disabled={codeArr.findIndex(v => !v) !== -1}
                onClick={() => handleOk(codeArr.join(''))}
            >
                Confirm
            </Button>
            <Button
                className="r-primaryNoBg_btn"
                disabled={countdown !== 0}
                style={{ marginTop: 20 }}
                onClick={() => resendClick()}
                loading={isSending}
            >
                {countdown === 0 ? 'Resend' : `(${Math.round(countdown / 1000)}s)`}
            </Button>
        </Modal>
    );
}

export default VerificationCodeDialog;
