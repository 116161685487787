import { DatePicker, Input, Select } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import React, { ReactElement, useCallback, useState } from 'react';
import './index.scss';
import { debounce } from 'lodash';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
// type 0 [search,select,date], -1 [search,date], 1 [search]
function PageFilter({ searchPlaceholder, type, children, state, setState, getList }: any) {
    const { RangePicker } = DatePicker;
    // const [state, setState] = useState({
    //     pageNo: 1,
    //     search: '',
    //     beginTime: '' as any,
    //     endTime: '' as any,
    //     status: 'all',
    // });
    const [isSearch, setIsSearch] = useState(false);
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
            console.log('search');
        }, 1000),
        []
    );
    const changeTime = val => {
        if (val && val !== null) {
            const beginTime = dayjs(val[0]).format('YYYY-MM-DD');
            const endTime = dayjs(val[1]).format('YYYY-MM-DD');
            setState({ ...state, beginTime, endTime, pageNo: 1, time: val });
            getList({ ...state, beginTime, endTime, pageNo: 1, time: val });
            console.log('search');
            return;
        }
        setState({ ...state, endTime: '', beginTime: '', pageNo: 1, time: null });
        getList({ ...state, endTime: '', beginTime: '', pageNo: 1, time: null });
        console.log('search');
    };
    return (
        <div className="filter_top r-left">
            <Input
                placeholder={searchPlaceholder ? searchPlaceholder : 'Name/Email '}
                style={{ width: 347 }}
                value={state.search}
                allowClear
                onChange={e => {
                    setIsSearch(true);
                    searchList({ ...state, search: e.target.value, pageNo: 1 });
                    setState({ ...state, search: e.target.value, pageNo: 1 });
                }}
                className="r-input r-input_serach"
                prefix={<span className="search_icon"></span>}
            />
            <div className="space15"></div>
            {type === 1 ? (
                <>{children}</>
            ) : (
                <>
                    <RangePicker
                        className={`r-datePicker r-date_showLine`}
                        style={{ width: 290 }}
                        format="DD/MM/YYYY"
                        inputReadOnly
                        onChange={changeTime}
                        disabledDate={disabledDate}
                        value={state.time}
                        suffixIcon={<></>}
                        separator={<div></div>}
                        placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                    />
                    {type === -1 ? null : (
                        <>
                            <div className="space15"></div>
                            <Select
                                style={{ width: 180, height: 48 }}
                                className="r-select r-select48 filter-sel"
                                value={state.status ? state.status : void 0}
                                placeholder="Select"
                                suffixIcon={<i className="sel_arrow_icon"></i>}
                                onChange={val => {
                                    setState({ ...state, status: val });
                                    getList({ ...state, status: val });
                                }}
                                options={[
                                    { value: 'all', label: 'All status' },
                                    { value: 'active', label: 'Active' },
                                    { value: 'suspended', label: 'Suspended' },
                                ]}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default PageFilter;
