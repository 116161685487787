import { Button, Input, Modal } from 'antd';
function DefaultModal({ isModalOpen, handleOk, handleCancel, children, title, width }) {
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={width ?? 530}
            title={
                <div className="modal-top-title" style={{ marginBottom: 25 }}>
                    {title}
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            {children}
        </Modal>
    );
}

export default DefaultModal;
