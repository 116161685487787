import axios from '../utils/index';
import { Pat } from '@/typings/request';
const subscriptionApi = {
    getRateList(callback: (T: any) => void) {
        axios
            .get(`/v1/admin/rate/rate-list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editRateInfo(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/rate/rate-edit`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSubscriptionList(callback: (T: any) => void) {
        axios
            .get(`/v1/admin/subscriptionPlan/plan-list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteSubPlan(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/admin/subscriptionPlan/plan-delete/${data.planId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addSubPlan(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/subscriptionPlan/subscriptionPlan-add`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editSubPlan(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/subscriptionPlan/plan-edit/${data.planId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSubscriptionRecordList(data: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/admin/industry/subscriptionRecord-list/${data.beginTime}/${data.endTime}/${data.pageNo}/${data.pageSize}`,
                data
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default subscriptionApi;
