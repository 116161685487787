import React, { Suspense, useState, useEffect } from 'react';
import { HashRouter, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AxiosInterceptor } from './utils/index';
import auto from '@/utils/autofont';
import '@/assets/fonts/font.scss';
import routes from './router';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceType } from '@/store/stateSlice';
import { Spin } from 'antd';
import useLoading from './hook/useLoading';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { FeedbackProvider } from './context/FeedbackContext';
const App = () => {
    const dispatch = useDispatch();
    const commonplace = useSelector((state: any) => state.commonplace);
    const [device, setDevice] = useState('pc');
    const [showPage, setShowPage] = useState(false);
    const { mainLoading, setLoadingStatus } = useLoading();
    const getDevice = () => {
        const clientWidth = document.documentElement.clientWidth;
        setDevice(clientWidth < 745 ? 'mb' : 'pc');
        dispatch(setDeviceType(clientWidth < 745 ? 'mb' : 'pc'));
        setTimeout(() => {
            setShowPage(true);
        }, 200);
    };
    window.onresize = function () {
        auto.autoFont();
        getDevice();
    };
    useEffect(() => {
        setLoadingStatus(false);
        auto.autoFont();
        getDevice();
    }, []);
    return (
        // <HashRouter basename={process.env.REACT_APP_BASE_NAME || ''}>
        <Router basename={process.env.REACT_APP_BASE_NAME || ''}>
            <FeedbackProvider>
                <AxiosInterceptor>
                    <Spin
                        spinning={mainLoading}
                        tip={''}
                        indicator={<Loading3QuartersOutlined spin style={{ fontSize: 32, color: '#4C47DE' }} />}
                        wrapperClassName={'fullLoading-box'}
                    >
                        <Suspense>
                            <Routes>
                                {routes.map((route, index) => {
                                    return (
                                        <Route
                                            path={route.path}
                                            key={index}
                                            element={
                                                <div
                                                    className={`${device}-main ${
                                                        showPage ? 'show-page' : 'hidden-page'
                                                    }`}
                                                >
                                                    <route.component routes={route.childrens ? route.childrens : []} />
                                                </div>
                                            }
                                        ></Route>
                                    );
                                })}
                            </Routes>
                        </Suspense>
                    </Spin>
                </AxiosInterceptor>
            </FeedbackProvider>
        </Router>
    );
};

export default App;
