import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import KeepAlive from 'react-activation';
import usePrevious from '@/hook/usePrevious';
import Logo from '@/assets/img/Logo.png';
import { defaultInfo } from '@/typings/allType';
import { useFeedback } from '@/context/FeedbackContext';
import AlertModal from '@/components/modal/alert-modal';
const LayoutMain = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { feedbackCount } = useFeedback();
    const prevLocation: any = usePrevious(location);
    const [activeIndex, setActiveIndex] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navList = [
        { isActive: true, lebel: 'Business', url: '/business', key: 1 },
        { isActive: false, lebel: 'Candidates', url: '/candidates', key: 2 },
        // { isActive: false, lebel: 'Job Role', url: '/job-role', key: 3 },
        { isActive: false, lebel: 'Subscription', url: '/subscription', key: 4 },
        { isActive: false, lebel: 'Feedback', url: '/feedback', key: 5 },
    ];
    const changeNav = (val: defaultInfo) => {
        setActiveIndex(val.key);
        navigate(val.url);
    };
    const logout = () => {
        setIsModalOpen(true);
    };
    const confirmOp = () => {
        navigate('/login');
        window.sessionStorage.clear();
    };
    const activeList = [
        { url: '/business', key: 1 },
        { url: '/business-detail', key: 1 },
        { url: '/candidates', key: 2 },
        { url: '/candidates-detail', key: 2 },
        { url: '/subscription', key: 4 },
        { url: '/subscription-record', key: 4 },
        { url: '/feedback', key: 5 },
    ];
    useEffect(() => {
        const path = location.pathname;
        const active = activeList.find(v => v.url === path);
        setActiveIndex(active?.key || 1);
    }, []);
    return (
        <>
            <div className="layout-container">
                <div className="header_nav r-left">
                    <img src={Logo} className="header-logo" alt="" />
                    <div className="r-left">
                        {navList.map(item => {
                            return (
                                <div
                                    className={`navItem r-left ${activeIndex === item.key ? 'active' : ''}`}
                                    key={item.key}
                                    onClick={() => changeNav(item)}
                                >
                                    {item.key === 3 || (item.key === 5 && feedbackCount) ? (
                                        <i className="red-roundBox"></i>
                                    ) : null}
                                    {item.lebel}
                                </div>
                            );
                        })}
                    </div>
                    <i className="off-icon marLeft" onClick={() => logout()}></i>
                    <div className="admin-icon r-center">A</div>
                </div>
                <div className="layout-content">
                    <Routes>
                        {props.routes.map((route: any, index: any) => {
                            return route.KeepAlive ? (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <KeepAlive cacheKey={route.path} saveScrollPosition="screen" name={route.name}>
                                            <route.component
                                                prevLocation={prevLocation}
                                                routes={route.childrens ? route.childrens : []}
                                            />
                                        </KeepAlive>
                                    }
                                ></Route>
                            ) : (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <route.component
                                            prevLocation={prevLocation}
                                            routes={route.childrens ? route.childrens : []}
                                        />
                                    }
                                ></Route>
                            );
                        })}
                    </Routes>
                </div>
            </div>
            <AlertModal
                message={'Are you sure you want to log out of the current account?'}
                cancelText={'Cancel'}
                confirmText={'Confirm Logout'}
                isModalOpen={isModalOpen}
                handleOk={() => confirmOp()}
                handleCancel={() => setIsModalOpen(false)}
            />
        </>
    );
};

export default LayoutMain;
