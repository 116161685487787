import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { setUserStatus } from '@/store/stateSlice';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const instance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    timeout: 360000,
});
instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = sessionStorage.getItem('admin-satoken');
        if (token) {
            (config.headers as AxiosRequestHeaders)['talenaut-admin-token'] = token;
        }
        (config.headers as AxiosRequestHeaders)['Accept-Language'] = process.env.REACT_APP_ACCEPT_LANGUAGE ?? 'en-US';
        (config.headers as AxiosRequestHeaders)['timestamp'] = new Date().getTime();
        const currentTimezone = dayjs.tz.guess();
        (config.headers as AxiosRequestHeaders)['timeZone'] = currentTimezone;
        (config.headers as AxiosRequestHeaders)['ngrok-skip-browser-warning'] = true;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

const AxiosInterceptor = ({ children }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const resInterceptor = (response: any) => {
        const code = response.data.code;
        if (code === 90003 || code === 90002) {
            sessionStorage.clear();
            localStorage.clear();
            window.utils.alert('error', response.data.message);
            navigate('/login');
            return { ...response, data: { code } };
        } else if (code === 30002) {
            dispatch(setUserStatus(2));
            return { ...response, data: { code } };
        } else if (code === 30003) {
            dispatch(setUserStatus(0));
            return { ...response, data: { code } };
        } else if (code === 50003) {
            dispatch(setUserStatus(3));
            return { ...response, data: { code } };
        } else {
            dispatch(setUserStatus(1));
            if (response.headers['admin-satoken'])
                sessionStorage.setItem('admin-satoken', response.headers['admin-satoken']);
            return response;
        }
    };

    const errInterceptor = (error: any) => {
        return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

    useEffect(() => {
        return () => instance.interceptors.response.eject(interceptor);
    }, [navigate]);

    return children;
};

export default instance;
export { AxiosInterceptor };
