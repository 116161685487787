import React, { useEffect, useState } from 'react';
import './style/index.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Spin } from 'antd';
import EditIcon from '@/assets/img/edit.png';
import DeleteIcon from '@/assets/img/delete.png';
import DefaultModal from '@/components/modal/default-modal';
import api from '@/api/subscription';
import useLoading from '@/hook/useLoading';
import { defaultInfo } from '@/typings/allType';
import AlertModal from '@/components/modal/alert-modal';
import { setProfileInfo } from '@/store/stateSlice';
const { TextArea } = Input;
function formatAmount(amount) {
    return amount
        .toFixed(2) // 保留两位小数
        .replace(/\d(?=(\d{3})+\.)/g, '$&,'); // 使用正则添加千位分隔符
}
const Sunscription = () => {
    const navigate = useNavigate();
    const { setLoadingStatus } = useLoading();
    const [rateModal, setRateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState('Create New Plan');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteAlertModal, setDeleteAlertModal] = useState(false);
    const [totalValue, setTotalValue] = useState({
        subscriptionRecord: 0,
        totalSubscription: 0,
        totalSubscriptionEuro: 0,
        totalSubscriptionUSD: 0,
    });
    const [conversionRate, setConversionRate] = useState<any>([
        { unit: '£', tip: 'gbp', label: 'GBP', price: '1.00' },
        { unit: '€', tip: 'euro', label: 'Euro', price: '1.18' },
        { unit: '$', tip: 'usd', label: 'USD', price: '1.27' },
    ]);
    const [rateInfo, setRateInfo] = useState({
        euro: 0,
        usd: 0,
    });
    const [monthPlanList, setMonthPlanList] = useState<defaultInfo[]>([]);
    const [annualPlanList, setAnnualPlanList] = useState<defaultInfo[]>([]);
    const [opType, setOpType] = useState(0);
    const [planInfo, setPlanInfo] = useState({
        planName: '',
        teamSeatType: 0,
        teamSeat: '',
        monthPrice: '',
        isMonth: true,
        annualPrice: '',
        isAnnual: true,
        description: '',
        planId: '',
        id: '',
    });
    const getRate = () => {
        api.getRateList(res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                conversionRate.forEach(item => {
                    item.price = data.rate[item.tip].toFixed(2);
                });
                setConversionRate([...conversionRate]);
                setTotalValue({
                    subscriptionRecord: formatAmount(data.subscriptionRecord || 0),
                    totalSubscription: formatAmount(data.totalSubscription || 0),
                    totalSubscriptionEuro: formatAmount(data.totalSubscriptionEuro || 0),
                    totalSubscriptionUSD: formatAmount(data.totalSubscriptionUSD || 0),
                });
                setRateInfo({
                    euro: data.rate['euro'],
                    usd: data.rate['usd'],
                });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const editRate = () => {
        setLoading(true);
        api.editRateInfo({ ...rateInfo }, res => {
            const { code, message } = res;
            setLoading(false);
            if (code === 200) {
                setRateModal(false);
                conversionRate[1].price = Number(rateInfo.euro).toFixed(2);
                conversionRate[2].price = Number(rateInfo.usd).toFixed(2);
                setConversionRate([...conversionRate]);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const getSubList = () => {
        api.getSubscriptionList(res => {
            setPageLoading(false);
            const { data, code, message } = res;
            // setLoadingStatus(false);
            if (code === 200) {
                if (data.monthlyPlanList && data.monthlyPlanList.length) {
                    setMonthPlanList(
                        data.monthlyPlanList.map(v => Object.assign(v, { price: window.utils.formatMoney(v.price) }))
                    );
                }
                if (data.annualPlanList && data.annualPlanList.length) {
                    setAnnualPlanList(
                        data.annualPlanList.map(v => Object.assign(v, { price: window.utils.formatMoney(v.price) }))
                    );
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const opeationPlan = (type, val) => {
        setOpType(type);
        if (type === 0) {
            const initialValues = {
                planName: '',
                teamSeatType: 0,
                teamSeat: '',
                monthPrice: '',
                isMonth: true,
                annualPrice: '',
                isAnnual: true,
                description: '',
                planId: '',
                id: '',
            };
            setPlanInfo(initialValues);
            form.setFieldsValue({ ...initialValues });
            setModalTitle('Create New Plan');
            setIsModalOpen(true);
            return;
        }
        const initialValues = {
            planName: val.planName,
            teamSeatType: val.seatType === 'bySeats' ? 0 : val.seatType === 'limited' ? 1 : 2,
            teamSeat: val.seatsNumber,
            monthPrice: val.price,
            isMonth: val.planType === 'monthlyPlan',
            annualPrice: val.price,
            isAnnual: val.planType === 'annualPlan',
            description: val.description,
            planId: val.planId,
            id: val.id,
        };
        setPlanInfo({ ...initialValues });
        if (type === 1) {
            setModalTitle('Edit');
            form.setFieldsValue(initialValues);
            setIsModalOpen(true);
        } else {
            if (
                (val.planType === 'monthlyPlan' && monthPlanList.length === 1) ||
                (val.planType === 'annualPlan' && annualPlanList.length === 1)
            ) {
                window.utils.alert(
                    'error',
                    'There must be at least one month plan and one year plan, which cannot be deleted.'
                );
                return;
            }
            setDeleteAlertModal(true);
        }
    };
    const confirmDel = () => {
        setLoading(true);
        api.deleteSubPlan({ planId: planInfo.planId }, res => {
            const { code, message } = res;
            setLoading(false);
            if (code === 200) {
                setDeleteAlertModal(false);
                getSubList();
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const handleChange = (e, type) => {
        const inputValue = e.target.value;
        // Only allow numbers and up to two decimal places
        const reg = /^(?!0\d)\d{0,9}(\.\d{0,2})?$/;
        if (reg.test(inputValue) || inputValue === '') {
            if (type === 1) {
                setProfileInfo({ ...planInfo, monthPrice: inputValue });
                form.setFieldsValue({ monthPrice: inputValue });
            } else {
                setProfileInfo({ ...planInfo, annualPrice: inputValue });
                form.setFieldsValue({ annualPrice: inputValue });
            }
        }
    };
    const [form] = Form.useForm();
    const saveSubInfo = () => {
        if (
            !planInfo.planName ||
            (planInfo.teamSeatType === 1 && !Number(planInfo.teamSeat)) ||
            (planInfo.isMonth && !planInfo.monthPrice) ||
            (planInfo.isAnnual && !planInfo.annualPrice)
        ) {
            return;
        }
        setLoading(true);
        const pat: defaultInfo = {
            description: planInfo.description,
            planName: planInfo.planName,
            seatType: planInfo.teamSeatType === 0 ? 'bySeats' : planInfo.teamSeatType === 1 ? 'limited' : 'unlimited',
            priceMonthly: planInfo.isMonth ? +planInfo.monthPrice : '',
            priceAnnual: planInfo.isAnnual ? +planInfo.annualPrice : '',
            seatsNumber: planInfo.teamSeat || 0,
        };
        if (planInfo.planId) {
            pat.planId = planInfo.planId;
            pat.planType = planInfo.isMonth ? 'monthlyPlan' : 'annualPlan';
            pat.price = planInfo.isMonth ? +planInfo.monthPrice : +planInfo.annualPrice;
            api.editSubPlan(pat, res => opCallback(res));
        } else {
            api.addSubPlan(pat, res => opCallback(res));
        }
    };
    const opCallback = (res: defaultInfo) => {
        const { code, message } = res;
        setLoading(false);
        if (code === 200) {
            setIsModalOpen(false);
            getSubList();
        } else {
            window.format.alert('error', message);
        }
    };
    const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {
        // setLoadingStatus(true);
        setPageLoading(true);
        getRate();
        getSubList();
    }, []);
    return (
        <div className="c-pageContent-subscription">
            <h1>Subscription</h1>
            {pageLoading ? (
                <div className="r-center" style={{ padding: '80px 0' }}>
                    <Spin style={{ position: 'static' }} />
                </div>
            ) : (
                <>
                    <div className="sub-topBox r-left">
                        <div className="rate-box">
                            <div className="subBox-tit">Conversion Rate</div>
                            <div className="r-left">
                                {conversionRate.map(item => {
                                    return (
                                        <div className="rate-item" key={item.label}>
                                            <div className="price-tip">{item.label}</div>
                                            <div className="price-box">
                                                {item.unit}
                                                {item.price}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="r-center" style={{ marginTop: 40 }}>
                                <Button className="r-primaryNoBg_btn jobNobg-btn" onClick={() => setRateModal(true)}>
                                    Edit Conversion Rate
                                </Button>
                            </div>
                        </div>
                        <div className="total-box">
                            <div className="subBox-tit">Total subscription value</div>
                            <div className="price-box-total" style={{ margin: '20px 0' }}>
                                <p>£{totalValue.totalSubscription ?? '0.00'}</p>
                                <p style={{ margin: '10px 0' }}>€{totalValue.totalSubscriptionEuro ?? '0.00'}</p>
                                <p>${totalValue.totalSubscriptionUSD ?? '0.00'}</p>
                            </div>
                            <div className="r-center" style={{ marginTop: 30 }}>
                                <Button
                                    className="r-primaryNoBg_btn jobNobg-btn"
                                    disabled={!Number(totalValue.subscriptionRecord)}
                                    onClick={() => navigate('/subscription-record')}
                                >
                                    Subscription Record
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="sub-plan">
                        <div className="r-left plan-tit">
                            Subscription Plan
                            <Button
                                style={{ width: 160 }}
                                onClick={() => opeationPlan(0, '')}
                                className="r-primaryNoBg_btn jobNobg-btn marLeft"
                            >
                                Create New Plan
                            </Button>
                        </div>
                        <div>
                            <div className="plan-btn">Monthly Plan</div>
                            <div className="planList-box r-left">
                                {monthPlanList.length ? (
                                    monthPlanList.map((item, i) => {
                                        return (
                                            <div key={`m-card-${i}`} className="plan-item">
                                                <div className="r-left">
                                                    <div style={{ maxWidth: '65%' }}>
                                                        <div className="plan-name oneLineText">{item.planName}</div>
                                                        <p className="plan-teamNum">
                                                            Team Seats:{' '}
                                                            {item.seatType === 'bySeats'
                                                                ? '1+'
                                                                : item.seatType === 'limited'
                                                                ? item.seatsNumber
                                                                : 'Unlimited'}
                                                        </p>
                                                    </div>
                                                    <div className="plan-price marLeft">£{item.price}</div>
                                                </div>
                                                <div className="plan-line"></div>
                                                <div className="r-end">
                                                    <img
                                                        src={EditIcon}
                                                        onClick={() => opeationPlan(1, item)}
                                                        className="plan-opIcon"
                                                        alt=""
                                                    />
                                                    <img
                                                        src={DeleteIcon}
                                                        onClick={() => opeationPlan(2, item)}
                                                        className="plan-opIcon marl30"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="r-center" style={{ width: '100%', margin: 45 }}>
                                        No data
                                    </div>
                                )}
                            </div>
                            <div className="plan-btn">Annual Plan</div>
                            <div className="planList-box r-left">
                                {annualPlanList.length ? (
                                    annualPlanList.map((item, i) => {
                                        return (
                                            <div key={`an-card-${i}`} className="plan-item">
                                                <div className="r-left">
                                                    <div style={{ maxWidth: '65%' }}>
                                                        <div className="plan-name oneLineText">{item.planName}</div>
                                                        <p className="plan-teamNum">
                                                            Team Seats:{' '}
                                                            {item.seatType === 'bySeats'
                                                                ? '1+'
                                                                : item.seatType === 'limited'
                                                                ? item.seatsNumber
                                                                : 'Unlimited'}
                                                        </p>
                                                    </div>
                                                    <div className="plan-price marLeft">£{item.price}</div>
                                                </div>
                                                <div className="plan-line"></div>
                                                <div className="r-end">
                                                    <img
                                                        src={EditIcon}
                                                        onClick={() => opeationPlan(1, item)}
                                                        className="plan-opIcon"
                                                        alt=""
                                                    />
                                                    <img
                                                        src={DeleteIcon}
                                                        onClick={() => opeationPlan(2, item)}
                                                        className="plan-opIcon marl30"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="r-center" style={{ width: '100%', margin: 45 }}>
                                        No data
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* rate info */}
            <DefaultModal
                title="Edit Conversion Rate"
                width={700}
                isModalOpen={rateModal}
                handleOk={() => {
                    setRateModal(false);
                    setRateInfo({
                        euro: conversionRate[1].price,
                        usd: conversionRate[2].price,
                    });
                }}
                handleCancel={() => {
                    setRateModal(false);
                    setRateInfo({
                        euro: conversionRate[1].price,
                        usd: conversionRate[2].price,
                    });
                }}
                children={
                    <div>
                        <div style={{ margin: '25px 0 60px 0' }}>
                            <div className="rateModalLabel-box r-left">
                                <span className="label-w">GBP</span>
                                <span className="rateModalPrice">£ 1.00</span>
                            </div>
                            <div className="rateModalLabel-box r-left" style={{ margin: '30px 0' }}>
                                <span className="label-w">Euro</span>
                                <Input
                                    style={{ width: 510 }}
                                    className={`r-input`}
                                    // maxLength={15}
                                    autoComplete="off"
                                    placeholder={''}
                                    value={rateInfo.euro}
                                    onInput={(e: any) => {
                                        const val = window.utils.validateNumberInput(e.target.value);
                                        setRateInfo({ ...rateInfo, euro: val });
                                    }}
                                    prefix={<i className="price-input">€</i>}
                                />
                            </div>
                            <div className="rateModalLabel-box r-left">
                                <span className="label-w">USD</span>
                                <Input
                                    style={{ width: 510 }}
                                    className={`r-input`}
                                    // maxLength={15}
                                    autoComplete="off"
                                    placeholder={''}
                                    value={rateInfo.usd}
                                    onInput={(e: any) => {
                                        const val = window.utils.validateNumberInput(e.target.value);
                                        setRateInfo({ ...rateInfo, usd: val });
                                    }}
                                    prefix={<i className="price-input">$</i>}
                                />
                            </div>
                        </div>
                        <Button
                            className="r-primary_btn"
                            disabled={!Number(rateInfo.euro) || !Number(rateInfo.usd)}
                            loading={loading}
                            onClick={() => editRate()}
                        >
                            Save
                        </Button>
                    </div>
                }
            />
            {/* plan info */}
            <DefaultModal
                title={modalTitle}
                width={700}
                isModalOpen={isModalOpen}
                handleOk={() => {
                    setIsModalOpen(false);
                    form.resetFields();
                }}
                handleCancel={() => {
                    setIsModalOpen(false);
                    form.resetFields();
                }}
                children={
                    <Form
                        name="basic-planForm"
                        layout="vertical"
                        // initialValues={{ remember: true }}
                        autoComplete="off"
                        form={form}
                        className="planInfo-form"
                        onFinish={saveSubInfo}
                        onFinishFailed={e => {
                            console.log(e);
                        }}
                    >
                        <div style={{ margin: '25px 0 60px 0' }}>
                            <Form.Item
                                label="Plan Name"
                                name="planName"
                                rules={[{ required: true, message: 'Please enter a plan name' }]}
                            >
                                <Input
                                    className={`r-input`}
                                    maxLength={50}
                                    autoComplete="off"
                                    value={planInfo.planName}
                                    onInput={(e: any) => {
                                        const val = e.target?.value;
                                        setPlanInfo({ ...planInfo, planName: val });
                                    }}
                                    placeholder={'Plan Name'}
                                />
                            </Form.Item>
                            <p className="form-tip mart20">Team Seat</p>
                            <div className="r-left">
                                <div
                                    className="r-left"
                                    onClick={() => setPlanInfo({ ...planInfo, teamSeatType: 0, teamSeat: '' })}
                                >
                                    <i className={`radio_icon ${!planInfo.teamSeatType ? 'radioed_icon' : ''}`}></i>
                                    <span className="r-radioText">By seats</span>
                                </div>
                                <div
                                    className="r-left cursorPointer"
                                    style={{ marginLeft: 185 }}
                                    onClick={() => setPlanInfo({ ...planInfo, teamSeatType: 1, teamSeat: '' })}
                                >
                                    <i className={`radio_icon ${planInfo.teamSeatType == 1 ? 'radioed_icon' : ''}`}></i>
                                    <span className="r-radioText">Limited</span>
                                </div>
                                <div
                                    className="r-left cursorPointer"
                                    style={{ marginLeft: 185 }}
                                    onClick={() => setPlanInfo({ ...planInfo, teamSeatType: 2, teamSeat: '' })}
                                >
                                    <i className={`radio_icon ${planInfo.teamSeatType == 2 ? 'radioed_icon' : ''}`}></i>
                                    <span className="r-radioText">Unlimited</span>
                                </div>
                            </div>
                            {planInfo.teamSeatType === 1 ? (
                                <Form.Item
                                    label=""
                                    name="teamSeat"
                                    rules={[
                                        { required: planInfo.teamSeatType === 1, message: 'Please set team seats' },
                                    ]}
                                >
                                    <Input
                                        className={`r-input mart15`}
                                        maxLength={4}
                                        autoComplete="off"
                                        placeholder="Please enter number of seats"
                                        value={planInfo.teamSeat}
                                        onBlur={(e: any) => {
                                            if (e.target.value && +e.target.value > 9999) {
                                                e.target.value = 9999;
                                                setPlanInfo({ ...planInfo, teamSeat: '9999' });
                                                form.setFieldValue('teamSeat', '9999');
                                            }
                                        }}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/\D/g, '');
                                            const val = e.target?.value;
                                            setPlanInfo({ ...planInfo, teamSeat: val });
                                            form.setFieldValue('teamSeat', val);
                                        }}
                                    />
                                </Form.Item>
                            ) : (
                                <></>
                            )}
                            <p className="form-tip mart20">Price</p>
                            <div className="r-left">
                                <div
                                    className="r-left"
                                    style={{ display: modalTitle === 'Edit' && !planInfo.isMonth ? 'none' : '' }}
                                >
                                    <div
                                        className={`r-left ${modalTitle === 'Edit' ? 'disabled' : ''}`}
                                        style={{ marginBottom: 25 }}
                                        onClick={() => {
                                            if (modalTitle === 'Edit') return;
                                            setPlanInfo({ ...planInfo, isMonth: !planInfo.isMonth });
                                        }}
                                    >
                                        <i className={`check_icon ${planInfo.isMonth ? 'checked_icon' : ''}`}></i>
                                        <span className="r-radioText" style={{ width: 80, marginLeft: 10 }}>
                                            Monthly
                                        </span>
                                    </div>
                                    <Form.Item
                                        label=""
                                        name="monthPrice"
                                        rules={[
                                            {
                                                required: planInfo.isMonth,
                                                message: 'Please enter a price',
                                            },
                                            {
                                                validator(_, value) {
                                                    if (!value || value > 0 || !planInfo.isMonth) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Please enter a price'));
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!planInfo.isMonth}
                                            style={{ width: 200 }}
                                            className={`r-input`}
                                            maxLength={10}
                                            autoComplete="off"
                                            placeholder={''}
                                            prefix={<i className="price-input">£</i>}
                                            value={planInfo.monthPrice}
                                            // onInput={e => handleChange(e, 1)}
                                            onBlur={e => {
                                                let inputValue = window.utils.validateInput(e.target.value);
                                                if (+inputValue > 9999999.99) {
                                                    inputValue = '9999999.99';
                                                    form.setFieldValue('monthPrice', '9999999.99');
                                                    setPlanInfo({
                                                        ...planInfo,
                                                        monthPrice: '9999999.99',
                                                    });
                                                }
                                            }}
                                            onChange={(e: any) => {
                                                const inputValue = window.utils.validateInput(e.target.value);
                                                form.setFieldValue('monthPrice', inputValue);
                                                setPlanInfo({
                                                    ...planInfo,
                                                    monthPrice: window.utils.validateInput(e.target.value),
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div
                                    className="r-left"
                                    style={{
                                        marginLeft: modalTitle === 'Edit' ? 0 : 28,
                                        display: modalTitle === 'Edit' && !planInfo.isAnnual ? 'none' : '',
                                    }}
                                >
                                    <div
                                        className={`r-left ${modalTitle === 'Edit' ? 'disabled' : ''}`}
                                        style={{ marginBottom: 25 }}
                                        onClick={() => {
                                            if (modalTitle === 'Edit') return;
                                            setPlanInfo({ ...planInfo, isAnnual: !planInfo.isAnnual });
                                        }}
                                    >
                                        <i className={`check_icon ${planInfo.isAnnual ? 'checked_icon' : ''}`}></i>
                                        <span className="r-radioText" style={{ width: 80, marginLeft: 10 }}>
                                            Annual
                                        </span>
                                    </div>
                                    <Form.Item
                                        label=""
                                        name="annualPrice"
                                        rules={[
                                            {
                                                required: planInfo.isAnnual,
                                                message: 'Please enter a price',
                                            },
                                            {
                                                validator(_, value) {
                                                    if (!value || value > 0 || !planInfo.isAnnual) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Please enter a price'));
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!planInfo.isAnnual}
                                            style={{ width: 200 }}
                                            className={`r-input`}
                                            maxLength={10}
                                            autoComplete="off"
                                            placeholder={''}
                                            prefix={<i className="price-input">£</i>}
                                            value={planInfo.annualPrice}
                                            // onInput={e => handleChange(e, 2)}
                                            onBlur={e => {
                                                let inputValue = window.utils.validateInput(e.target.value);
                                                if (+inputValue > 9999999.99) {
                                                    inputValue = '9999999.99';
                                                    form.setFieldValue('annualPrice', '9999999.99');
                                                    setPlanInfo({
                                                        ...planInfo,
                                                        annualPrice: '9999999.99',
                                                    });
                                                }
                                            }}
                                            onChange={(e: any) => {
                                                const inputValue = window.utils.validateInput(e.target.value);
                                                form.setFieldValue('annualPrice', inputValue);
                                                setPlanInfo({
                                                    ...planInfo,
                                                    annualPrice: window.utils.validateInput(e.target.value),
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <p className="form-tip">Description (optional)</p>
                            <div className="textArea-box">
                                <TextArea
                                    className={`r-inputTextArea r-input`}
                                    maxLength={300}
                                    autoComplete="off"
                                    autoSize={false}
                                    placeholder=""
                                    value={planInfo.description}
                                    onInput={(e: any) => {
                                        const val = e.target?.value;
                                        setPlanInfo({ ...planInfo, description: val });
                                    }}
                                />
                                <span className="text-count">{planInfo.description.length || 0}/300</span>
                            </div>
                        </div>
                        <Button
                            className={`r-primary_btn ${
                                !planInfo.planName ||
                                (planInfo.teamSeatType === 1 && !Number(planInfo.teamSeat)) ||
                                (planInfo.isMonth && !planInfo.monthPrice) ||
                                (planInfo.isAnnual && !planInfo.annualPrice)
                                    ? 'disabled'
                                    : ''
                            }`}
                            loading={loading}
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form>
                }
            />
            <AlertModal
                message="Are you sure you want to remove this plan?"
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={deleteAlertModal}
                handleOk={() => confirmDel()}
                loading={loading}
                handleCancel={() => setDeleteAlertModal(false)}
            />
        </div>
    );
};

export default Sunscription;
