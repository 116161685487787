import Login from '@/views/login/login';
import ResetPassword from '@/views/login/resetPassword';
import ResetSuccess from '@/views/login/success';
import Index from '@/views/pages/index';
import Business from '@/views/pages/business';
import BusinessDetail from '@/views/pages/business/business-detail';
import Candidates from '@/views/pages/candidates';
import CandidatesDetail from '@/views/pages/candidates/candidates-detail';
import JobRole from '@/views/pages/jobRole';
import Subscription from '@/views/pages/subscription';
import FeedBack from '@/views/pages/feedback';
import JobRoleDetail from '@/views/pages/jobRole/jobRole-detail';
import CustomerJobRoleDetail from '@/views/pages/jobRole/customer-jobRole-detail';
import SubscriptionRecord from '@/views/pages/subscription/subscription-record';
export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
    icon?: any;
    key?: number;
}
const routes: RouteConfigDeclaration[] = [
    // {
    //     path: '',
    //     component: Login,
    //     exact: true,
    //     auth: false,
    // },
    // {
    //     path: '/',
    //     component: Login,
    //     exact: true,
    //     auth: false,
    // },
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/reset-success',
        component: ResetSuccess,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'Business',
                path: '/business',
                component: Business,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Business',
                key: 0,
            },
            {
                name: 'BusinessDetail',
                path: '/business-detail',
                component: BusinessDetail,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Business',
                key: 0,
            },
            {
                name: 'Candidates',
                path: '/candidates',
                component: Candidates,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Candidates',
                key: 1,
            },
            {
                name: 'CandidatesDetail',
                path: '/candidates-detail',
                component: CandidatesDetail,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Candidates',
                key: 1,
            },
            {
                name: 'JobRole',
                path: '/job-role',
                component: JobRole,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'JobRole',
                key: 2,
            },
            {
                name: 'JobRoleDetail',
                path: '/job-role-detail',
                component: JobRoleDetail,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'JobRoleDetail',
                key: 2,
            },
            {
                name: 'CustomerJobRoleDetail',
                path: '/customer-job-role-detail',
                component: CustomerJobRoleDetail,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'CustomerJobRoleDetail',
                key: 2,
            },
            {
                name: 'Subscription',
                path: '/subscription',
                component: Subscription,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Subscription',
                key: 3,
            },
            {
                name: 'SubscriptionRecord',
                path: '/subscription-record',
                component: SubscriptionRecord,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'SubscriptionRecord',
                key: 3,
            },
            {
                name: 'FeedBack',
                path: '/feedback',
                component: FeedBack,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'FeedBack',
                key: 4,
            },
        ],
    },
];

export default routes;
