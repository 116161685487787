import axios from '../utils/index';
import { Pat } from '@/typings/request';
const feedbackApi = {
    getFeedbackList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/admin/feedback-list/${data.beginTime}/${data.endTime}/${data.pageNo}/${data.pageSize}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    readFeedBack(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/admin/feedbacks-read/${data.id}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default feedbackApi;
