import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.scss';
function ResetSuccess() {
    const navigate = useNavigate();
    return (
        <div className="login-main r-center">
            <div className="center-box" style={{ padding: '85px 40px 65px 40px' }}>
                <div className="login-formBox">
                    <div className="r-center">
                        <img className="success_img" src={require('@/assets/img/success.png')} alt="" />
                    </div>
                    <div className="r-center succ_tit">Reset successfully</div>
                    <Button
                        style={{ margin: '40px 0 0 0' }}
                        className="r-primaryNoBg_btn"
                        onClick={() => navigate('/login')}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ResetSuccess;
