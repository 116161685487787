import React, { useState } from 'react';
import { Button, Form, FormProps, Input, message, Modal } from 'antd';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import { useNavigate } from 'react-router-dom';
import ForgetSendEmailModal from '@/components/modal/forget-send-email-modal';
import './index.scss';
import { useCountDown } from 'ahooks';
import api from '@/api/login';
import JSEncrypt from 'jsencrypt';
function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const passwordEncrypt = new JSEncrypt();
    const [eyeOpen, setEyeOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSendModalOpen, setIsSendModalOpen] = useState(false);
    const [passwordEmail, setPasswordEmail] = useState('');
    const [state, setState] = useState({
        email: '',
        password: '',
    });

    const handleOk = (codeStr: string) => {
        api.verifyEmailCode(
            {
                email: passwordEmail,
                code: codeStr,
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    setIsModalOpen(false);
                    window.sessionStorage.setItem('code', codeStr);
                    window.sessionStorage.setItem('email', passwordEmail);
                    navigate('/reset-password');
                } else {
                    window.utils.alert('error', res.message);
                }
            }
        );
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const [form] = Form.useForm();
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleValidate();
        }
    };
    const handleValidate = async () => {
        try {
            const values = await form.validateFields();
            console.log('Validation Success:', values);
            setLoading(true);
            api.getPublicKey(res => {
                const { code, data } = res;
                if (code === 200 && data) {
                    passwordEncrypt.setPublicKey(data);
                    api.login({ email: state.email, password: passwordEncrypt.encrypt(state.password) }, loginRes => {
                        if (loginRes.code === 200) {
                            window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                            navigate('/business');
                        } else {
                            message.error(loginRes.message);
                        }
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                    message.error(res.message);
                }
            });
        } catch (error) {
            setLoading(false);
            console.log('Validation Failed:', error);
        }
    };
    const [targetDate, setTargetDate] = useState<number>();
    const [countdown] = useCountDown({
        targetDate,
        onEnd: () => {
            setTargetDate(0);
        },
    });
    const [sendLoading, setSendLoading] = useState(false);
    const resendClick = (email: string) => {
        if (targetDate || isSending) return;
        setIsSending(true);
        if (email) {
            setPasswordEmail(email);
        }
        const pat = {
            email: email ? email : passwordEmail,
        };
        setSendLoading(true);
        api.sendEmailCode(pat, res => {
            setSendLoading(false);
            const { code, data } = res;
            if (code === 200) {
                setIsSendModalOpen(false);
                setIsModalOpen(true);
                setTargetDate(Date.now() + 60000);
                window.utils.alert('success', 'Sent successfully');
            } else {
                window.utils.alert('error', res.message);
            }
            setIsSending(false);
        });
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    return (
        <div className="login-main r-center">
            <div className="center-box">
                <img className="logo-img" src={require('@/assets/img/talenaut_logo.png')} alt="" />
                <div className="login-formBox">
                    <Form
                        name="basic-login"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        validateTrigger="onBlur"
                        className="login-form"
                        form={form}
                    >
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter a valid email address' },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input
                                className="r-input"
                                value={state.email}
                                onInput={(e: any) => setState({ ...state, email: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item label="Password" name="password" rules={[{ validator: validatePassword }]}>
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={' '}
                                value={state.password}
                                onKeyDown={handleKeyDown}
                                onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                type={eyeOpen ? 'text' : 'password'}
                                suffix={
                                    eyeOpen ? (
                                        <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                    </Form>
                    <div className="forgetBox">
                        <span onClick={() => setIsSendModalOpen(true)}>Forgot password</span>
                    </div>
                    <Button
                        className="r-primary_btn"
                        disabled={!state.email || !state.password || loading}
                        onClick={() => handleValidate()}
                        loading={loading}
                    >
                        Login
                    </Button>
                </div>
            </div>
            <ForgetSendEmailModal
                loading={sendLoading}
                isModalOpen={isSendModalOpen}
                handleOk={email => {
                    resendClick(email);
                }}
                handleCancel={() => setIsSendModalOpen(false)}
            />
            <VerificationCodeDialog
                isSending={isSending}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                countdown={countdown}
                resendClick={resendClick}
            />
        </div>
    );
}

export default Login;
