import { useEffect, useState } from 'react';
import { defaultInfo } from '@/typings/allType';
import PageFilter from '@/components/page-filter';
import Pagination from '@/components/pagination';
import { Table, Image } from 'antd';
import DefaultModal from '@/components/modal/default-modal';
import './style/index.scss';
import api from '@/api/feedback';
import dayjs from 'dayjs';
import { useFeedback } from '@/context/FeedbackContext';
const FeedBack = () => {
    const [status, setStatus] = useState('showData');
    const { refreshFeedbackCount } = useFeedback();
    const [isSearch, setIsSearch] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [feedbackInfo, setFeedbackInfo] = useState<defaultInfo>({});
    const handlePageChange = (prop: number) => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        time: null,
        startTime: '' as any,
        endTime: '' as any,
        status: 'all',
        pageSize: 10,
    });
    const [tableData, setTableData] = useState<any>([]);
    const columns = [
        {
            title: 'Full Name',
            key: 'displayName',
            render: val => (
                <div className="cell-txt r-left" style={{ position: 'relative' }}>
                    {val.readTime ? null : <i className="red-roundBox"></i>}
                    <Image
                        width={32}
                        height={32}
                        preview={false}
                        style={{ borderRadius: '50%', minWidth: 32 }}
                        src={val.profilePhoto}
                        fallback={require('@/assets/img/user-default.png')}
                    />
                    <span style={{ marginLeft: 10 }}>{val.displayName || '-'}</span>
                </div>
            ),
        },
        {
            title: 'Email',
            key: 'email',
            render: val => <div className="cell-txt">{val.email || '-'}</div>,
        },
        {
            title: 'Reason',
            key: 'feedbackType',
            render: val => <div className="cell-txt">{val.feedbackType || 'Feedback'}</div>,
        },
        {
            title: 'Content',
            key: 'feedback',
            width: 300,
            render: val => (
                <div className="cell-txt oneLineText" style={{ maxWidth: 300 }}>
                    {val.feedback || '-'}
                </div>
            ),
        },
        {
            title: 'Submission Date',
            key: 'createTime',
            render: val => <div className="cell-txt">{val.createTime || '-'}</div>,
        },
        {
            title: '',
            width: 100,
            render: val => (
                <div className="cell-txt cell-text-underline" onClick={() => handClickRow(val)}>
                    View
                </div>
            ),
        },
    ];
    const handClickRow = val => {
        console.log(val);
        setFeedbackInfo(val);
        setIsModalOpen(true);
        api.readFeedBack({ id: val.id }, res => {
            const { code, message } = res;
            if (code !== 200) {
                window.format.alert('error', message);
                return;
            }
            refreshFeedbackCount();
            getList({ ...state });
        });
    };
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNo: val.pageNo,
            pageSize: state.pageSize,
            beginTime: val.beginTime ? dayjs(`${val.beginTime} 00:00:00`).valueOf() / 1000 : 0,
            endTime: val.endTime ? dayjs(`${val.endTime} 23:59:59`).valueOf() / 1000 : 9999999999,
            keyWord: val.search,
            status: val.status === '-1' ? '' : val.status,
        };
        setStatus('loading');
        api.getFeedbackList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.list.length > 0) {
                const list = data.list.map(item => {
                    const fullName = `${item.firstName ? item.firstName : ''}${
                        item.middleName ? ' ' + item.middleName : ''
                    }${item.lastName ? ' ' + item.lastName : ''}`;
                    return Object.assign(item, {
                        createTime: item.createTime ? window.format.foramtTimeDMY(+item.createTime * 1000) : '-',
                        displayName: fullName ? fullName : '-',
                        mobileNumber: item.mobileNumber ? window.utils.formatNumber(item.mobileNumber) : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.totalRecord);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.totalRecord);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    useEffect(() => {
        getList({ ...state });
    }, []);
    return (
        <div className="c-pageContent-feedback">
            <h1>Feedback</h1>
            <div className="pad-top">
                <PageFilter
                    searchPlaceholder="Name/Email"
                    state={state}
                    setState={setState}
                    getList={getList}
                    type={-1}
                />
            </div>
            <Table
                columns={columns}
                dataSource={tableData}
                rowKey={val => val.id}
                rowHoverable={false}
                className="r-table click_row"
                rowClassName={() => 'r-tableRow'}
                pagination={false}
            />
            {status === 'showData' && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
            <DefaultModal
                title="Feedback"
                width={700}
                isModalOpen={isModalOpen}
                handleOk={() => setIsModalOpen(false)}
                handleCancel={() => setIsModalOpen(false)}
                children={
                    <div className="feed-modal">
                        <div className="r-left">
                            <Image
                                width={32}
                                height={32}
                                preview={false}
                                style={{ borderRadius: '50%', minWidth: 32 }}
                                src={feedbackInfo.profilePhoto}
                                fallback={require('@/assets/img/user-default.png')}
                            />
                            <span style={{ marginLeft: 10 }}>{feedbackInfo.displayName || '-'}</span>
                        </div>
                        <div className="r-left" style={{ margin: '5px 0' }}>
                            <span className="modal-blackText">{feedbackInfo.email || '-'}</span>
                            <span className="modal-blackText" style={{ marginLeft: 30 }}>
                                {feedbackInfo.mobileNumber || '-'}
                            </span>
                        </div>
                        <div className="modal-blackText op-color">{feedbackInfo.createTime || '-'}</div>
                        <div style={{ margin: '25px 0' }}>
                            <div className="modal-blackText op-color">Reason:</div>
                            <div className="modal-blackText mart5">{feedbackInfo.feedbackType || '-'}</div>
                        </div>
                        <div className="modal-blackText op-color">Content:</div>
                        <div className="modal-blackText mart5">{feedbackInfo.feedback || '-'}</div>
                    </div>
                }
            />
        </div>
    );
};

export default FeedBack;
